import $ from 'jquery';

export default function lockHeroHeight() {
  const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  const vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

  if(vw <= 768) {
    $('.c-hero__splash').each(function(){
      $(this).css('min-height', Math.max(parseInt($(this).css('min-height')), vh) + 'px');
    });
  }
}
