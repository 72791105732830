/**
 * Toggle class on click
 */

import $ from 'jquery';
import getTargetByTrigger from 'Utils/getTargetByTrigger';

import PubSub from 'pubsub-js';
let publish = PubSub.publish;
// let subscribe = PubSub.subscribe;

export default function toggleClass() {

  let $doc = $(document);

  const onClick = function (e) {

    let $target;
    let $this = $(this);

    e.preventDefault();

    // Get the content
    $target = getTargetByTrigger($this);
    if (!$target) return;

    let classToToggle = $this.data('toggle-class');

    // Toggle the content
    $target.toggleClass( classToToggle );

    publish('toggle-class/click/toggled', $target);

    // Focus API
    if($this.data('focus-target') && $target.hasClass(classToToggle) ) {
      let $focusTarget = $( $this.data('focus-target') );
      if($focusTarget.length > 0) {
        setTimeout(function(){
          $focusTarget.focus();
        }, $this.data('focus-delay') || 0);
      }
    }

  };

  const onHover = function (e) {

    let $target;
    let $this = $(this);

    // Get the content
    $target = getTargetByTrigger($this);
    if (!$target) return;

    let classToToggle = $this.data('toggle-class');

    // Toggle the content
    $target.toggleClass( classToToggle, e.type === 'mouseenter' );

  };

  const onDismiss = function(e){
    $('[data-toggle-class][data-dismissable]').each(function(){
      let $that = $(this);
      if(!$that.is(e.target)) {
        let $target = $($that.data('target'));
        if (!$target) return;

        if($.contains($target[0], e.target) || $.contains($target[0], e.currentTarget)) {
          return;
        }
        $target.removeClass($that.data('toggle-class'));
      }
    });
  };

  $doc.on('click', '[data-toggle-class][data-trigger=click]', onClick);
  $doc.on('click', onDismiss);
  $doc.on('mouseenter mouseleave', '[data-toggle-class][data-trigger=hover]', onHover);

}
