// Source: https://codepen.io/shivasurya/pen/FatiB?editors=1010

import $ from 'jquery';
import {getElementCookie, setElementCookie} from 'Utils/elementCookieHelpers';

export default function siteAnnouncements() {
  let $html = $('html');
  let $page = $('#page');
  let $header = $('.c-header');
  $('.c-site-announcement').each(function () {
    let $announcement = $(this);

    if (getElementCookie($announcement)) {
      return true;
    }

    let delay = $announcement.data(delay) || 16;
    setTimeout(function () {
      let announcementHeight = $announcement.outerHeight();
      $html.addClass('is-site-announcement-active');
      $page.css('padding-top', announcementHeight + 'px');
      $header.css('margin-top', announcementHeight + 'px');
      $announcement.css('margin-top', announcementHeight + 'px');
    }, delay);

    $announcement.on('click', '[data-dismiss="site-announcement"]', function () {
      setElementCookie($announcement);
      $html.removeClass('is-site-announcement-active');
      $page.css('padding-top', '');
      $header.css('margin-top', '');
      $announcement.css('margin-top', '');
    });
  });
}
