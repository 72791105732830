import $ from 'jquery';
import {getElementCookie} from 'Utils/elementCookieHelpers';

export default function clickRider() {
  $('[data-ride="click"]').each(function(){
    let $this = $(this);

    if (getElementCookie($this)) {
      return true;
    }

    let delay = parseInt($this.data('click-delay'));
    if(delay > 0) {
      setTimeout(function(){
        $this.trigger('click');
      }, delay);
    } else {
      $this.trigger('click');
    }
  });
}
