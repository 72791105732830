// Source: https://codepen.io/shivasurya/pen/FatiB?editors=1010

import $ from 'jquery-easing';

export default function animatedNumbers() {
  $('.o-animated-number').each(function () {
    let $this = $(this);
    let $classTargets = $this.add($this.parent());
    let duration = $this.data('duration') || 4000;
    $this.prop('Counter',0).animate({
      Counter: $this.text()
    }, {
      duration: duration,
      easing: 'swing', // https://easings.net/
      step: function (now) {
        $this.text(Math.ceil(now));
      },
      complete: function(){
        $classTargets.addClass('is-completed');
      }
    });
    $classTargets.addClass('is-ready');
  });
}
