// Source: https://codepen.io/shivasurya/pen/FatiB?editors=1010

import $ from 'jquery';
import jsCookie from 'js-cookie';

export function getElementCookie(element) {
  let $element = $(element);
  let cookieName = $element.data('cookie-name');
  if (cookieName) {
    return jsCookie.get(cookieName);
  }

  return null;
}

export function setElementCookie(element, value) {
  value = typeof value === 'undefined' ? 1 : value;
  let $element = $(element);
  let cookieName = $element.data('cookie-name');
  if (cookieName) {
    let cookieExpires = $element.data('cookie-expires') || 24; // 1 = an hour
    cookieExpires = cookieExpires / 24;
    jsCookie.set(cookieName, value, {expires: cookieExpires});
  }
}
