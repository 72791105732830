import $ from 'jquery';
import {throttle} from 'lodash';

import PubSub from 'pubsub-js';
const publish = PubSub.publish;
// const subscribe = PubSub.subscribe;

// let $doc = $(document);
let $html = $('html');
// let $body = $('body');
let $header = $('.c-header');
let $headerSearch = $('.c-header__search');

export default function scrollClasses() {
  /**
   * Page scroll events
   */

  let lastScrollTop = 0;
  let lastScrollTopTime;

  const getBodyScrollTop = function () {
    const el = document.scrollingElement || document.documentElement;
    return el.scrollTop;
  };

  let published = false;

  window.addEventListener('scroll', throttle(function () {

    console.log('scroll', lastScrollTop);

    if (window.preventScrollClasses) return;

    // let headerHeight = $header.outerHeight();
    // let headerHeight = getSelectorsHeight(globalOffsetSelector);
    // let scrollLimit = $header.outerHeight() + $headerSearch.outerHeight();
    let scrollLimit = $header.outerHeight() + $headerSearch.outerHeight();

    // let w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    // let h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

    let scrollLargeLimit = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

    // let scrollTop = $win.scrollTop();
    let scrollTop = getBodyScrollTop();
    let isScrolled = scrollTop > scrollLimit;
    let isScrolledLarge = scrollTop > scrollLargeLimit;
    let isScrolledToTop = scrollTop < lastScrollTop;
    // let isScrolledToPageBottom = $win.scrollTop() + $win.height() === $doc.height();
    // let isScrolledToPageBottom = $html.scrollTop + $html.outerHeight() === $html.outerHeight();

    // console.log('scroll', isScrolled, isScrolledLarge, isScrolledToTop, getBodyScrollTop());

    $html.toggleClass('is-scrolled', isScrolled);
    $html.toggleClass('is-not-scrolled', !isScrolled);
    $html.toggleClass('is-scrolled-large', isScrolledLarge);
    // $html.toggleClass('is-scrolled--to-page-bottom', isScrolled && isScrolledToPageBottom);

    /* jshint ignore:start */
    $html[0].offsetWidth;
    /* jshint ignore:end */
    setTimeout(function () {
      $html.toggleClass('is-scrolled--ready', isScrolled);
    }, 10);

    clearTimeout(lastScrollTopTime);
    lastScrollTopTime = setTimeout(
      function () {
        $html.toggleClass('is-scrolled--to-top', isScrolled && isScrolledToTop);
      },
      0
    );

    lastScrollTop = scrollTop;

    if(isScrolled && !published) {
      publish('/scroll-classes/scrolled');
    }
  }, 200));
}
