import $ from 'jquery';

export default function getTargetByTrigger(trigger) {
  let $trigger = $(trigger);
  let target = $trigger.data('target');
  if(!target) {
    return false;
  }

  target = target.split('|');

  switch(target[0]) {
    case 'closest':
      if(typeof target[1] === undefined) {
        return false;
      } else {
        return $trigger.closest(target[1]);
      }
      break;
    default:
      return $(target[0]);
      break;
  }
}
