// Source: https://codepen.io/shivasurya/pen/FatiB?editors=1010

import $ from 'jquery';

let $win = $(window);

const buildContent = function (data) {
  return '<div class="c-svg-map-tooltip">' +
    '<h3 class="c-svg-map-tooltip__title">' + data.title + '</h3>' +
    '<div class="c-svg-map-tooltip__content">' + data.content + '</div>' +
    '</div>';
};

export default function svgMaps() {
  $('[data-ride="svg-maps"]').each(function () {
    let $this = $(this);
    let source = $this.data('source');
    if (window[source] && $.isArray(window[source]) && window[source].length > 0) {
      let tooltipSelector = $this.data('tooltip-selector');
      let $tooltip = $this.find(tooltipSelector);
      $.each(window[source], function (i, data) {
        let $path = $this.find('#' + data.code);
        if ($path.length > 0) {
          /**
           * move to end to make strokes always visible.
           *
           * Because "first element -> "painted" first"
           *
           * https://stackoverflow.com/questions/17786618/how-to-use-z-index-in-svg-elements
           */
          $path.appendTo($path.parent());

          $path.addClass('is-painted');
          $path.data('tooltip-content', buildContent(data));
        }
      });

      if ($tooltip.length > 0) {
        $this.on('mouseover', function (e) {
          if (event.target.tagName === 'path') {
            if ($(event.target).data('tooltip-content')) {
              $tooltip.html('<div>' + $(event.target).data('tooltip-content') + '</div>');
              $this.addClass('has-tooltip');
            }
          }
        });
        $this.on('mouseout', function (e) {
          // $tooltip.empty();
          $this.removeClass('has-tooltip');
        });
        $this.on('mousemove', function (e) {
          $tooltip.css('top', (e.pageY - $this.offset().top) + 'px');
          $tooltip.css('left', (e.pageX - $this.offset().left) + 'px');
        });
      }

    }
  });
}
