import $ from 'jquery';
import createElement from 'Utils/createElement';

const ua = window.navigator.userAgent;

export function isMobileWidth() {
  return $('body').outerWidth() < 768;
}

export function hasObjectFit() {
  // objectFit returns false on Chrome but objectfit returns true
  return 'objectFit' in document.documentElement.style === true;
}

export function hasCSSFilters() {
  let el = createElement('a');
  el.style.cssText = '-webkit-filter:blur(2px); filter:blur(2px);';
  // https://github.com/Modernizr/Modernizr/issues/615
  // documentMode is needed for false positives in oldIE, please see issue above
  return !!el.style.length && ((document.documentMode === undefined || document.documentMode > 9));
}

export function hasCSSScrollbars() {
  // Tested Element
  let test = document.createElement('div');
  test.className = '__sb-test';
  test.style.overflow = 'scroll';
  test.style.width = '40px';

  // Is there another way to style pseudo-elements in JS ?
  let style = document.createElement('style');
  style.innerHTML = '.__sb-test::-webkit-scrollbar { width: 0px; }';

  // Apply
  document.body.appendChild(test);
  document.body.appendChild(style);

  // If css scrollbar is supported, than the scrollWidth should not be impacted
  let ret = test.scrollWidth === 40;

  // Cleaning
  document.body.removeChild(test);
  document.body.removeChild(style);

  return ret;
}

// export function hasHiddenScrollbars() {
//   // Tested Element
//   let test = document.createElement('div');
//   test.className = '__sb-test';
//
//   // Is there another way to style pseudo-elements in JS ?
//   let style = document.createElement('style');
//   style.innerHTML = '.__sb-test { width:100px;height:100px;overflow:scroll }';
//
//   // Apply
//   document.body.appendChild(test);
//   document.body.appendChild(style);
//
//   // Check scrollbar width
//   let ret = test.scrollWidth === test.clientWidth;
//
//   // Cleaning
//   document.body.removeChild(test);
//   document.body.removeChild(style);
//
//   return !ret;
// }

export function getScrollbarWidth() {
  return window.innerWidth - document.documentElement.clientWidth;
}

export function hasHiddenScrollbarsV2() {
  return getScrollbarWidth() === 0;
}

export function isIE() {
  return /(MSIE|Trident)/.test(ua);
}

export const featureTests = {
  "object-fit": hasObjectFit,
  "css-filters": hasCSSFilters,
  "css-scrollbars": hasCSSScrollbars,
  "hidden-scrollbars": hasHiddenScrollbarsV2
};

export const browserTests = {
  "ie": isIE
};

export default function detectFeatures() {
  const $html = $('html');

  $html.removeClass('no-js').addClass('js');

  for (let feature in featureTests) {
    $html.addClass((featureTests[feature]() ? 'has-' : 'no-') + feature);
  }

  for (let feature in browserTests) {
    if (browserTests[feature]()) {
      $html.addClass('is-' + feature);
    }
  }
}
