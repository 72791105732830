/*
 * @title Common
 * @description Common scripts
 */

import 'Utils/_array.polyfills';

import 'picturefill';
// import 'intersection-observer';
import $ from 'jquery';

window.$ = window.jQuery = $;

import detectFeatures from 'Utils/detectFeatures';
import matchHeight from 'Utils/matchHeight';
import formHelpers from 'Utils/formHelpers';
import recaptcha from 'Utils/recaptcha';
import scrollClasses from 'Utils/scrollClasses';
import carousel from 'Modules/carousel/carousel';
import lightbox from 'Modules/lightbox/lightbox';
import responsiveVideo from 'Modules/responsive-video/responsive-video';
import toggleClass from 'Components/toggle-class';
import LazyLoad from "vanilla-lazyload";
import SmoothScroll from 'smooth-scroll';
import scrolSpy from 'Utils/scrollSpy';
import responsiveImage from 'Utils/responsiveImage';
import animatedNumbers from 'Utils/animatedNumbers';
import svgMaps from 'Utils/svgMaps';
import responsiveTables from 'Utils/responsiveTables';
import siteAnnouncements from 'Utils/siteAnnouncements';
import lockHeroHeight from 'Utils/lockHeroHeight';
import menu from 'Utils/menu';
import clickRider from 'Utils/clickRider';

import PubSub from 'pubsub-js';
const publish = PubSub.publish;
const subscribe = PubSub.subscribe;

let $html = $('html');

let alreadyInit = false;
const init = function(){
  if(alreadyInit) {
    return;
  }

  alreadyInit = true;

  detectFeatures();

  let mhInstance = matchHeight();
  mhInstance._update();

  formHelpers();
  recaptcha();
  scrollClasses();
  toggleClass();
  carousel();
  lightbox();
  responsiveVideo();
  new SmoothScroll('a[href*="#"]:not([href="#"])');
  scrolSpy('[data-spy]');
  responsiveImage();
  animatedNumbers();
  svgMaps();
  responsiveTables();
  siteAnnouncements();
  lockHeroHeight();
  menu();
  clickRider();

  let lazyLoad = new LazyLoad({
    elements_selector: ".lazy",
    callback_loaded: function(el){
      window.picturefill({
        reevaluate: true, // Awesome :D
        elements: [el]
      });
      publish('/lazy/loaded', el);
    },
    callback_finish: function(){
      window.picturefill({
        reevaluate: true
      });
      publish('/lazy/finish');
    }
  });

  publish('all-scripts-are-loaded');

  let onScrolled = function(){
    lazyLoad.loadAll();
  };
  subscribe('/scroll-classes/scrolled', onScrolled);

  // let onLightboxLoaded = function(){
  //   window.picturefill({
  //     reevaluate: true
  //   });
  //   lazyLoad.loadAll();
  // };
  //
  // subscribe('/lightbox/loaded', onLightboxLoaded);
};

const loaded = function(){
  $html.addClass('is-window-loaded');
  init();
};

document.addEventListener('DOMContentLoaded', init);
window.addEventListener('load', loaded);
