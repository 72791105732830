/**
 * Responsive Image
 */

import $ from 'jquery';

import {imagesLoaded} from 'Utils/imagesLoaded';

let _previousResizeWidth = -1,
  _updateTimeout = -1;

const _update = function(event){
  $('.c-responsive-image--cover').each(function(){
    let $this = $(this);

    imagesLoaded(this, function(){
      let $img = $this.find('img');
      if($img.length > 0) {
        let src = $img[0].currentSrc || $img[0].src;
        $this.css('background-image', 'url('+src+')');
        if(!$this.hasClass('is-loaded')) {
          $this.addClass('is-loaded');
        }
      }
    });
  });
};

const update = function (throttle, event) {
  // prevent update if fired from a resize event
  // where the viewport width hasn't actually changed
  // fixes an event looping bug in IE8
  if (event && event.type === 'resize') {
    let windowWidth = $(window).width();
    if (windowWidth === _previousResizeWidth) {
      return;
    }
    _previousResizeWidth = windowWidth;
  }

  // throttle updates
  if (!throttle) {
    _update(event);
  } else if (_updateTimeout === -1) {
    _updateTimeout = setTimeout(function() {
      _update(event);
      _updateTimeout = -1;
    }, 80);
  }
};

const onResize = function(event){
  update(true, event);
};

export default function responsiveImage(){
  $(document).ready(_update);
  $(window).on('resize', onResize);
  $(window).on('orientationchange', onResize);
}
