/**
 * Images loaded
 */

import $ from 'jquery';
import PubSub from 'pubsub-js';
const subscribe = PubSub.subscribe;

export function wait(url, cb){
  let img = new Image();
  img.onload = function() {
    cb(url, 1);
  };
  img.onerror = function() {
    cb(url, 0);
  };
  img.src = url;
}

export function imagesLoaded(el, cb){
  let $images = $(el).find('img');
  let len = $images.length;
  if($images.length > 0) {
    let count = 0;
    $images.each(function(idx, el){
      let $image = $(this);

      if($image.is('[data-src]')) {
        // lazy
        subscribe('/lazy/loaded', function(topic, el){
          // console.log('onLazyLoad', topic, el);
          if($image.is(el)) {
            if(++count === len) {
              cb();
            }
          }
        });
      } else {
        wait(el.getAttribute('src'), function(){
          if(++count === len) {
            cb();
          }
        });
      }

    }, el);
  } else {
    cb();
  }
}
