import $ from 'jquery';

export default function responsiveTables() {
  $('.c-table--responsive').each(function(){
    let $table = $(this);
    let $th = $table.find('thead').first().find('th');
    $table.find('tbody > tr').each(function(){
      $(this).find('td,th').each(function(){
        $(this).attr('data-th', $.trim($th.eq( $(this).index() ).text()) );
      });
    });
  });
}
